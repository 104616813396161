<template>
  <b-container class="dashboard" fluid>
    <b-row>
      <b-col
        v-if="leftColumnActive"
        cols="12"
        :md="leftColumnSizeMedium"
        :xl="leftColumnSizeLarge"
      >
        <Intro v-if="introActive" />
        <Schedule v-if="scheduleActive" :selected="content.intro.schedule.selected" />
      </b-col>
      <b-col cols="12" :md="middleColumnSizeMedium" :xl="middleColumnSizeLarge">
        <Items :blocks="content.blocks" />
      </b-col>
      <b-col v-if="rightColumnActive" cols="12" :xl="rightColumnSizeLarge">
        <Chat
          v-if="interactionActive"
          :type="content.interaction.chat.type[locale]"
          :code="content.interaction.chat.id[locale]"
          :roomCode="content.interaction.chat.roomCode[locale]"
        />
        <!-- <OnlineUsers
          v-if="content.interaction.type[locale] == 'online-users'"
        /> -->
        <hr v-if="interactionActive && socialWallActive" />
        <OnlineUsers v-if="onlineUsersActive"/>
        <SocialWall v-if="socialWallActive" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Intro from "@/components/landing/Intro.vue";
import Items from "@/components/landing/Items.vue";
import Chat from "@/components/chat/Chat.vue";
import Schedule from "@/components/landing/Schedule.vue";
import SocialWall from "@/components/landing/SocialWall.vue";
import OnlineUsers from "@/components/landing/OnlineUsers.vue" 

export default {
  name: "Dashboard",
  components: {
    Intro,
    Items,
    Chat,
    Schedule,
    SocialWall,
    OnlineUsers
  },
  async created() {
    await this.$store.dispatch("Dash/fetchAll");
  },
  computed: {
    leftColumnSizeMedium() {
      return this.rightColumnActive ? 2 : 5;
    },
    leftColumnSizeLarge() {
      return this.rightColumnActive ? 2 : 3;
    },
    rightColumnSizeMedium() {
      return this.leftColumnActive ? 2 : 5;
    },
    rightColumnSizeLarge() {
      return this.leftColumnActive ? 3 : 3;
    },
    middleColumnSizeMedium() {
      return this.minimizeMiddleCol ? 8 : 8;
    },
    middleColumnSizeLarge() {
      return this.minimizeMiddleCol ? 7 : 9;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    content() {
      return this.$store.state.Dash.content;
    },
    interactionActive() {
      return this.content.interaction?.active;
    },
    chatActive() {
      return this.content.interaction?.chat?.active;
    },
    introActive() {
      return this.content.intro?.active;
    },
    schedule() {
      return this.$store.getters["Schedule/byTitle"](this.selected)
    },
    scheduleActive() {
      return this.content.intro?.schedule?.active;
    },
    socialWallActive() {
      return this.content.interaction.social?.active;
    },
    onlineUsersActive() {
      return this.content.interaction.onlineusers?.active;
    },
    leftColumnActive() {
      return this.introActive || this.scheduleActive;
    },
    rightColumnActive() {
      return this.interactionActive;
    },
    minimizeMiddleCol() {
      return this.rightColumnActive && this.introActive;
    },
    enlargeMiddleCol() {
      return !this.rightColumnActive && !this.introActive;
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard {
  flex-grow: 1;
  padding-bottom: 50px;
}
</style>
